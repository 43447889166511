.b-video {

    .b-video_wrap {
        position: relative;
        // border-radius: rem(5px);
        overflow: hidden;
        // box-shadow: 0 rem(10px) rem(30px) rgba($black, 0.2);
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: rem(8px);
            background: linear-gradient(to right, $third 0 calc(100% / 7), $fourth calc(100% / 7) calc((100% / 7) * 2), $fifth calc((100% / 7) * 2) calc((100% / 7) * 3), $sixth calc((100% / 7) * 3) calc((100% / 7) * 4), $second calc((100% / 7) * 4) calc((100% / 7) * 5), $primary calc((100% / 7) * 5) calc((100% / 7) * 6), $seventh calc((100% / 7) * 6) 100%);
            z-index: 3;
        }
        &::after {
            content: "";
            position: absolute;
            background-color: $primary-light;
            opacity: 0.8;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .b-video_deco_gauche {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
        .b-video_deco_droite {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }
    .b-video_content {
        position: relative;
        z-index: 4;
    }
    .b-video_player_wrap {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $primary;
            opacity: 0.19;
            z-index: 3;
        }
        .b-video_player {
            position: relative;
            z-index: 4;
        }
        .o-ratio_content_bg {
            transition: $speed $easing;
        }
        .c-button_icon {
            transition: $speed $easing;
            transform-origin: center;
        }
        &:hover {
            .o-ratio_content_bg {
                transform: scale(1.05);
            }
            .c-button_icon {
                transform: rotate(120deg);
            }
        }
    }
}