.c-button {
  padding: 0.8rem rem($unit-small);
  color: $primary;
  transition: $speed $easing;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  font-size: rem($font-size-h6);
  font-weight: $medium;
  border-radius: rem(45px);
  
  .c-button_label, .c-button_icon {
    position: relative;
    z-index: 2;
  }
  
  .c-button_icon {
    
  }
    
  &.-primary {
    background-color: $primary;
    color: $white;
  }

  &.-second {
    background-color: $second;
    color: $primary;
  }

  &.-white {
    background-color: $white;
    color: $primary;
  }
    
  // &.-round {
  //   width: rem(45px);
  //   height: rem(45px);
  //   justify-content: center;
  //   padding: 0;
  //   &.-big{
  //     width: rem(80px);
  //     height: rem(80px);
  //   }
  // }
  
  &.-outline {
    border: 1px solid $primary;
  }

  &.-outline-white {
    border: 1px solid $white;
    color: $white;
  }

  &.-round {
    width: rem(38px);
    height: rem(38px);
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .-rainbow {
    transition: 0.8s $easing;
    background-color: $primary;
    position: absolute;
    z-index: 1;
    left: -100%;
    width: 100%;
    height: 100%;
    border-radius: rem(45px);
    &.-right {
      right: -100%;
      left: auto;
    }
    &.-second {
      background-color: $second;
    }
    &.-third {
      background-color: $third;
    }
    &.-fourth {
      background-color: $fourth;
    }
    &.-fifth {
      background-color: $fifth;
    }
    &.-sixth {
      background-color: $sixth;
    }
    &.-seventh {
      background-color: $seventh;
    }
    &.-white {
      background-color: $white;
    }

    &.-delay0 {
      transition-delay: 0.3s;
    }
    &.-delay1 {
      transition-delay: 0.25s;
    }
    &.-delay2 {
      transition-delay: 0.2s;
    }
    &.-delay3 {
      transition-delay: 0.15s;
    }
    &.-delay4 {
      transition-delay: 0.1s;
    }
    &.-delay5 {
      transition-delay: 0.05s;
    }
    &.-delay6 {
      transition-delay: 0s;
    }
  }

  svg {
    width: rem(15px);
    max-height: rem(15px);
  }
  &.-thin {
    padding: rem($unit-tinier) rem($unit-small);
  }

  @include u-hocus {
    
    .-rainbow {
      left: 0;
      &.-right {
        right: 0;
        left: auto;
      }
      &.-delay0 {
        transition-delay: 0s;
      }
      &.-delay1 {
        transition-delay: 0.05s;
      }
      &.-delay2 {
        transition-delay: 0.1s;
      }
      &.-delay3 {
        transition-delay: 0.15s;
      }
      &.-delay4 {
        transition-delay: 0.2s;
      }
      &.-delay5 {
        transition-delay: 0.25s;
      }
      &.-delay6 {
        transition-delay: 0.3s;
      }
    }

    &.-outline-white {
      background-color: $white;
      color: $primary;
    }
    
  }

  &.-disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.c-button_header {
  padding: rem($unit-tiny);
  position: relative;
  font-weight: $medium;
  color: $primary;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $primary-light;
    transition: $speed $easing;
    z-index: -1;
  }

  @include u-hocus {
    color: $white;
    &::before {
      width: 100%;
    }
  }
}

.c-badge {
  color: $primary;
  padding: 0.8rem rem($unit-small);
  border-radius: rem(45px);
  transition: $speed $easing;
  border: 1px solid $primary;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;

  @include u-hocus {
    background-color: rgba($primary, 0.1);
  }
  &.is-active {
    color: $white;
    background-color: $primary;
  }
}