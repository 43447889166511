.b-icones{
    .b-icones_item {
        .b-icones_image {
            height: rem(100px);
            img, svg {
                max-height: 100%;
            }
        }
    }
}
