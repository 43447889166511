.b-chiffres {
    position: relative;
    .b-chiffres_item_deco {
        width: 100%;
        height: rem(8px);
    }
    .b-chiffres_item {
        background-color: rgba($primary-light, 0.15);
        height: calc(100% - #{rem($unit-small)} - #{rem($unit-tiny)} - #{rem(8px)});
    }
    .b-chiffres_deco {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .b-chiffres_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(64px);
        width: rem(64px);
        color: $white;
        background-color: $primary;
        border-radius: 50%;
    }
}