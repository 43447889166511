.b-accueil-slider {
    position: relative;
    // min-height: calc(100vh - #{rem($header-height)});
    overflow: hidden;
    .b-accueil-slider_images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        // min-height: calc(100vh - #{rem($header-height)});
        height: 100%;
        .b-accueil-slider_image {
            position: relative;
            // min-height: calc(100vh - #{rem($header-height)});
            height: 100%;
        }
        .c-slider_container {
            height: 100%;
        }
    }
    .b-accueil-slider_deco {
        position: absolute;
        pointer-events: none;
        bottom: -40vw;
        left: -20%;
        width: 100vw;
        z-index: 1;
        svg {
            width: 100%;
        }
    }
    .b-accueil-slider_dots {
        
    }
    .b-accueil-slider_wrap {
        position: relative;
        // min-height: calc(100vh - #{rem($header-height)});
        display: flex;
        align-items: flex-end;
        padding: rem($unit-large) 0 rem($unit);
        margin-top: rem($unit);
        .o-container {
            width: 100%;
        }
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        // height: rem(8px);
        height: 0;
        background: linear-gradient(to right, $third 0 calc(100% / 7), $fourth calc(100% / 7) calc((100% / 7) * 2), $fifth calc((100% / 7) * 2) calc((100% / 7) * 3), $sixth calc((100% / 7) * 3) calc((100% / 7) * 4), $second calc((100% / 7) * 4) calc((100% / 7) * 5), $primary calc((100% / 7) * 5) calc((100% / 7) * 6), $seventh calc((100% / 7) * 6) 100%);
        z-index: 1;
        transition: 1s $easing;
    }

    .b-accueil-slider_content {
        opacity: 0;
        transition: 1s $easing;
        transform: translateY(#{rem($unit-small)});
    }
    .b-accueil-slider_deco {
        svg {
            transition: 1s $easing;
            transform: rotate(-20deg) translateX(-200px);
        }
    }

    .b-accueil-slider_image {
        transition: 1s $easing;
        transform: scale(1.2);
    }

    @media (max-width: $to-huge) {
        .b-accueil-slider_deco {
            width: 110vw;
        }
    }
    @media (max-width: $to-big) {
        .b-accueil-slider_deco {
            bottom: -35vw;
            left: -30%;
            width: 120vw;
        }
    }
    @media (max-width: $to-medium) {
        .b-accueil-slider_deco {
            bottom: -40vw;
            left: -35%;
            width: 140vw;
        }
    }
    @media (max-width: $to-small) {
        .b-accueil-slider_deco {
            left: -50%;
            width: 170vw;
        }
    }
    @media (max-width: $to-tiny) {
        .b-accueil-slider_deco {
            left: -85%;
            width: 250vw;
        }
    }
}

.is-loaded.is-ready.is-animated {
    .b-accueil-slider {
        &::after {
            transition-delay: 1s;
            height: rem(8px);
        }
        .b-accueil-slider_deco {
            svg {
                transition-delay: 1s;
                transform: translateX(0) rotate(0);
            }
        }
        .b-accueil-slider_content {
            opacity: 1;
            transform: translateX(0);
            transition-delay: 1.1s;
        }
        .b-accueil-slider_image {
            transition-delay: 1s;
            transform: scale(1);
        }
    }
}

.is-loaded.is-ready.is-animated.is-transitioned {
    .b-accueil-slider {
        &::after {
            transition-delay: 0.6s;
        }
        .b-accueil-slider_deco {
            svg {
                transition-delay: 0.6s;
            }
        }
        .b-accueil-slider_content {
            transition-delay: 0.7s;
        }
        .b-accueil-slider_image {
            transition-delay: 0.6s;
        }
    }
}