.b-onglet{
    overflow: hidden;
    .o-container{
        display: flex;
        flex-direction: column;
    }

    .c-slider {
        width: 100%;
    }

    .b-onglet_nav{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        padding-top: rem($unit-small);

        .b-onglet_nav_title{
            cursor: pointer;
            padding: rem($unit-tiny);
            width: calc(100% - (#{rem($unit-tiny)} * 3));
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: rem(45px);
            background: lightgray;
            transition: $speed $easing;

            &:hover, &.is-active{
                background: $second;
                color: $white;
            }
        }
    }

    .b-onglet_container{
        overflow: hidden;

        display: grid;

        .b-onglet_text{
            transition: 0.5s ease;

            grid-row: 1;
            grid-column: 1;

            div{
                columns: 2;
                column-gap: rem($unit);

                span{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: inherit;
                }

                @media (max-width: $from-medium){
                    columns: 1;
                    column-gap: inherit;
                }
            }

            p{
                margin: 0 0 rem($unit-tiny) 0;
            }

            @media (max-width: 680px){
                padding-right: rem($unit);

                p{
                    columns: inherit;
                }
            }
        }
    }
}
