.b-media-texte{
    @media (max-width: $from-small){
        .b-media-texte_img_container{
            display: flex;
            justify-content: center;
        }

        .b-media-texte_img{
            width: 50%;
        }
    }    
}
