.c-dropdown {
    position: relative;
    z-index: 1;
    cursor: pointer;

    padding: rem($unit-tinier) $select-icon rem($unit-tinier) rem($unit-tinier);
    border: 2px solid $primary;
    background-color: $white;
    color: $primary;
    font-weight: $medium;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: $select-icon;
        background-image: url("../images/dropdown.svg");
        background-position: center;
        background-size: rem(8px);
        background-repeat: no-repeat;
        content: "";
        pointer-events: none;
    }
    .c-dropdown_options {
        position: absolute;
        visibility: hidden;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $primary;
        a {
            padding: rem($unit-tinier);
            display: block;
            color: $white;
        }
    }

    &:hover {
        .c-dropdown_options {
            visibility: visible;
        }
    }
}