.b-timeline {
    .b-timeline_item {
        padding-left: rem($unit);
        position: relative;
        display: grid;
        grid-template-columns: rem(22px) 1fr;
        gap: rem($unit-small);
        .b-timeline_item_step {
            position: relative;
            padding-top: 5px;
            .step {
                width: rem(22px);
                height: rem(22px);
                border: 6px solid $third;
                display: block;
                border-radius: 50%;
            }
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                background-color: $grey;
                height: calc(100% - #{rem(22px)});
                left: 50%;
                bottom: -5px;
                transform: translateX(-50%);
                z-index: -1;
            }
        }
        .b-timeline_item_content {
            padding-bottom: rem($unit);
        }
        &:nth-child(6n+2) {
            .b-timeline_item_step {
                .step {
                    border-color: $fourth;
                }
            }
        }
        &:nth-child(6n+3) {
            .b-timeline_item_step {
                .step {
                    border-color: $fifth;
                }
            }
        }
        &:nth-child(6n+4) {
            .b-timeline_item_step {
                .step {
                    border-color: $sixth;
                }
            }
        }
        &:nth-child(6n+5) {
            .b-timeline_item_step {
                .step {
                    border-color: $second;
                }
            }
        }
        &:nth-child(6n+6) {
            .b-timeline_item_step {
                .step {
                    border-color: $seventh;
                }
            }
        }
        &:last-of-type {
            .b-timeline_item_content {
                padding-bottom: 0;
            }   
            .b-timeline_item_step {
                &::after {
                    display: none;
                }
            }
        }
    }
}
