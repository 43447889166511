.u-c-primary {
  color: $primary;
}
.u-c-primary-light {
  color: $primary-light;
}
.u-c-second {
  color: $second;
}
.u-c-third {
  color: $third;
}
.u-c-fourth {
  color: $fourth;
}
.u-c-fifth {
  color: $fifth;
}
.u-c-sixth {
  color: $sixth;
}
.u-c-seventh {
  color: $seventh;
}
.u-c-black {
    color: $black;
}
.u-c-white {
    color: $white;
}
.u-c-grey {
  color: $grey;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-primary-light {
  background-color: $primary-light;
}
.u-bg-primary-extra-light {
  background-color: rgba($primary-light, 0.15);
}
.u-bg-second {
  background-color: $second;
}
.u-bg-third {
  background-color: $third;
}
.u-bg-fourth {
  background-color: $fourth;
}
.u-bg-fifth {
  background-color: $fifth;
}
.u-bg-sixth {
  background-color: $sixth;
}
.u-bg-seventh {
  background-color: $seventh;
}
.u-bg-black {
    background-color: $black;
}
.u-bg-white {
    background-color: $white;
}
