.c-card {
  position: relative;
  overflow: hidden;
  a:hover {
    color: initial;
  }

  &.-actualite {
    background-color: rgba($primary-light, 0.15);
    transition: background-color $speed $easing;
    .c-card_image {
      height: rem(120px);
      overflow: hidden;
      position: relative;
      img {
        transition: $speed $easing;
      }
    }
    .c-card_content {
      height: calc(100% - #{rem(120px)});
      display: flex;
      flex-direction: column;

    } 
    .c-card_foot {
      margin-top: auto;
    }
    .c-card_date {
      background-color: $fifth;
      color: $primary;
      padding: rem($unit-tinier);
      position: absolute;
      bottom: 0;
      left: rem($unit-tiny);
    }
    &:hover {
      background-color: rgba($primary-light, 0.24);
      .c-card_image {
        img {
          transform: scale(1.05);
        }
      }
      .c-button {
        background-color: $primary;
        color: $white;
      }
    }   
  }

  &.-equipe {
    .c-card_image {
      height: rem(230px);
    }
    .c-card_content {
      height: calc(100% - #{rem(230px)});
    }
  }

  .c-card_deco {
    @media (max-width: $to-small) {
      svg {
        max-height: rem(150px);
        width: auto;
      }
    }
  }
}

.c-slider {
  .c-card {
    &.-actualite {
      .c-card_content {
        height: calc(100% - #{rem(120px)} - (#{rem($unit-tiny)} * 2));
      }
    }
  }
}