.b-cta{
    .b-cta_content{
        padding: rem($unit-large) rem($unit-large);
        position: relative;
    }
    .b-cta_deco {
        position: absolute;
        top: 0;
        right: 0;
    }
    
    @media (max-width: $from-medium){
        .b-cta_content{
            padding: rem($unit);
        }
    }
    @media (max-width: $from-tiny){
        .b-cta_content{
            padding: rem($unit-small);
        }
    }
}
