.b-liens {
    .b-liens_item {
        .b-liens_item_wrap {
            position: relative;
            overflow: hidden;
            .b-liens_item_deco {
                position: absolute;
                left: 0;
                bottom: 0;
                color: $white;
                width: 100%;
                max-height: 6rem;
                svg {
                    width: 100%;
                }
            }
        }
    }
}