// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #333333;

$primary: #2D2E83;
$primary-light: #73A1B8;
$second: #009FE3;
$third: #E6332A;
$fourth: #F39200;
$fifth: #F5CB4E;
$sixth: #95C11F;
$seventh: #82368C;

$green: #77C483;
$red: #D1603E;

$grey: #666666;
$grey-light: #F0F0F0;

$black-overlay-light: rgba(0, 0, 0, 0.5);


// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: $second;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
