.b-etapes {
    .b-etapes_item{
        display: flex;
        gap: rem($unit-small);
        align-items: flex-start;
    }
    .b-etapes_item_step {

    }
}
