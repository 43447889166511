.b-relation {
    .c-slider_container {
        .swiper-slide {
            .c-card {
                opacity: 0.5;
                transition: $speed $easing;
            }
            &.swiper-slide-active, &.swiper-slide-next {
                .c-card {
                    opacity: 1;
                }
            }
        }
    }
}

.b-relation_events {
    position: relative;
    .deco {
        position: absolute;
        top: rem(-$unit);
        left: 0;
        transform: translateY(-50%);
    }
    .b-relation_events_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: rem(9px);
        overflow: hidden;
        position: relative;
        .deco-club {
            position: absolute;
        }
    }
}

.b-relation_projets {
    position: relative;
    .deco {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
    }
}
.b-relation_projects_header {
    display: flex;
    align-items: flex-end;
}
.b-relation_projects_action {
    margin-left: auto;
}
.b-relation_projects_cat-image {
    position: absolute;
    height: 100%;
    width: 34vw;
    border-radius: rem(7px);
    overflow: hidden;
}

.b-relation_filtres {
    .c-form_item {
        max-width: rem(280px);
    }
}

.b-relation_clubs {
    .b-relation_clubs_wrap {
        display: flex;
        flex-direction: column;
        height: calc(100vh - #{rem($header_height)});
    }
    .b-relation_clubs_filtres {
        padding: rem($unit-small) rem($unit);
        .c-form {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: rem($unit-small);
        }
    }
    .b-relation_clubs_listing_wrap {
        padding: rem($unit-small) rem($unit);
        overflow: auto;
        position: relative;
        z-index: 2;
    }
    .b-relation_clubs_map_wrap {
        position: relative;
        z-index: 2;
    }
}

.b-relation_filtres{
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // form{
    //     gap: 2.5rem 1.5rem;
        
    //     label{
    //         span{
    //             width: fit-content;
    //             padding: 1rem 3rem;
    //             border-radius: rem(35px);
    //             border: 2px solid $second;
    //             transition: 0.3s $easing;
    //             cursor: pointer;
    //         }

    //         &:hover{
    //             span{
    //                 background-color: rgba($color: $second, $alpha: 0.24);
    //             }
    //         }
            
    //         input[type="radio"]:checked+span{
    //             color: white;
    //             background-color: $second;
    //             cursor: default;
    //         }
    //     }
    // }

    .swiper-slide {
        width: auto!important;
    }
}

.b-relation_filtres_wrap {
    display: flex;
    align-items: center;
    grid-gap: rem($unit-tiny);
    flex-wrap: wrap;
}

.c-pagination {
    .c-button {
        margin: 0 rem($unit-tinier);
    }
    .c-pagination_number {
        color: $primary;
        padding: 0.2rem rem($unit-tinier);
        position: relative;
        &.-disabled {
            opacity: 1;
            font-weight: $bold;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $primary;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.b-relation .c-slider_container .swiper-slide .c-card {
    opacity: 1!important;
}