.b-fichiers {
    .b-fichiers_wrap {
        .b-fichiers_link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-gap: rem($unit-tiny);
            padding: rem($unit-tiny);
            border-bottom: 1px solid $black;
            &:hover {
                background-color: $grey-light;
                color: $second;
            }
        }
    }
    .b-fichiers_ext {
        text-transform: uppercase;
        font-size: rem($font-size - 2px);
    }
    .b-fichiers_icon {
        svg {
            width: 1rem;
        }
    }

    .b-fichiers_pipe {
        margin: 0 rem($unit-tinier);
        &:last-of-type {
            display: none;
        }
    }
}