.b-tiroirs {
    .b-tiroirs_container{
    }
    
    .b-tiroirs_content{
        display: flex;
        align-items: center;

        figure{
            padding-left: 2rem;
        }

        @media (max-width: $from-medium){
            flex-direction: column;

            figure{
                padding-left: inherit;
                order: 1;
                margin-bottom: 2rem;
            }

            .b-tiroirs_texte{
                order: 2;
            }
        }
    }
}
