.c-text{
    a {
        color: $primary;
    }
    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(#{rem(22px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(16px);
                height: rem(16px);
                border: 4px solid $primary-light;
                border-radius: 50%;
            }
        }
    }
    img {
        width: 100%;
    }
}

blockquote {
    color: $primary;
    font-weight: $bold;
    text-align: center;
    font-size: rem($font-size-h4);
}
