.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  z-index: 99;
  display: flex;
  height: 100vh;
  width: 100vw;
  
  transition: transform 0.9s $easing 1s, visibility 0s 2s;

  display: flex;
  align-items: center;
  justify-content: center;

  .orange{
    height: 0;
    background-color: $second;
    transition: height 0.9s $easing 1s, visibility 2.5s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .c-loader_logo {
    max-width: rem(280px);
  }
}

.is-animated.is-ready , .is-transitioned, .is-transitioning {
  .c-loader {
    transform: translateY(-100%);
    visibility: hidden;

    .orange{
      height: 30vh;
    }
  }
}
