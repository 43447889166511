.c-breadcrumb {
    text-transform: uppercase;
    a {
        color: $white;
    }
    span {
        color: rgba($white, 0.64);
    }
    .c-breadcrumb_separator {
        margin: 0 rem($unit-tinier);
        color: $white;
    }
}