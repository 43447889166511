.b-accueil-map {
    position: relative;
    .b-accueil-map_deco {
        position: absolute;
        left: 0;
        top: 0;
        svg {
            transform: scaleX(-1);
        }
    }
    .b-accueil-map_point {
        display: block;
        width: rem(16px);
        height: rem(16px);
        background-color: $fifth;
        border-radius: 50%;
        border: 1px solid rgba($primary, 0.32);
        &.-big {
            width: rem(24px);
            height: rem(24px);
        }
    }

    .map-point {
        transition: $speed $easing;
    }

    svg {
        max-width: 100%;
    }
}