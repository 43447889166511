.c-accordion_item {
  border-bottom: 1px solid $primary;
  &:first-of-type {
    border-top: 1px solid $primary;
  }
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem($unit-small) 0;
    transition: $speed $easing;
    &:hover {
      padding: rem($unit-small);
      background-color: rgba($primary-light, 0.2);
      .c-button {
        background-color: $primary;
      }
      .c-accordion_plus {
        &::after, &::before {
          border-color: $white;
        }
      }
    }
  }
  .c-accordion_plus {
    position: relative;
    width: 1rem;
    height: 1rem;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 3px solid $primary;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -1.5px;
    }
    &::after {
      border-left: 3px solid $primary;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1.5px;
    }
    .icon {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        max-width: 50px;
      }
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.is-open {
    .c-accordion_header{
      // border-bottom: 2px solid $second;
      margin-bottom: 2rem;
      padding: rem($unit-small);
      background-color: rgba($primary-light, 0.2);
    }
    .c-accordion_main {
      height: auto;
      padding-bottom: 2rem;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-button {
      background-color: $primary;
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
    .c-accordion_plus::before {
      border-top: 3px solid $white;
    }
  }
}
