// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
    $font-din,
    "fonts/DIN PRO/dinprolight"
) {
    font-style:  normal;
    font-weight: 300;
}

@include font-face(
    $font-din,
    "fonts/DIN PRO/DINPro"
) {
    font-style:  normal;
    font-weight: 400;
}

@include font-face(
    $font-din,
    "fonts/DIN PRO/dinpromedium1"
) {
    font-style:  normal;
    font-weight: 500;
}

@include font-face(
    $font-din,
    "fonts/DIN PRO/dinprobold"
) {
    font-style:  normal;
    font-weight: 700;
}

