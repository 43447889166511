.c-footer{
    .c-footer_link {
        opacity: 0.55;
        font-size: rem($font-size-h3);
        font-weight: $medium;
        &:hover {
            color: $white;
            opacity: 1;
        }
    }
    @media (max-width: $to-medium) {
        .u-text-right {
            text-align: left!important;
        }
      }
}
