.b-intro {
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        // height: rem(8px);
        height: 0;
        background: linear-gradient(to right, $third 0 calc(100% / 7), $fourth calc(100% / 7) calc((100% / 7) * 2), $fifth calc((100% / 7) * 2) calc((100% / 7) * 3), $sixth calc((100% / 7) * 3) calc((100% / 7) * 4), $second calc((100% / 7) * 4) calc((100% / 7) * 5), $primary calc((100% / 7) * 5) calc((100% / 7) * 6), $seventh calc((100% / 7) * 6) 100%);
        z-index: 1;
        transition: $speed $easing;
    }

    .b-intro_image {
        // clip-path: url(#masque_photo_chapeau);
        // mask-image: url('../images/masque_photo_chapeau.svg');
        // mask-repeat: no-repeat;
        // mask-size: 100%;
        // width: 40vw;
    }
    @media (max-width: $to-small) {
        .b-intro_image {
            width: 100vw;
        }
    }

    .b-intro_image_anim {
        transition: 1s $easing;
        transform: translateX(150%) rotate(20deg);
    }
    .b-intro_anim {
        transition: 1s $easing;
        transform: translateX(-250%);
    }

    .c-breadcrumb {
        opacity: 0;
        transition: 1s $easing;
        transform: translateY(#{rem(-$unit-small)});
    }
    .b-intro_share {
        opacity: 0;
        transition: 1s $easing;
        transform: translateY(#{rem($unit-small)});
    }
}

.is-loaded.is-ready.is-animated {
    .b-intro {
        &::after {
            transition-delay: 1.5s;
            height: rem(8px);
        }
        .b-intro_image_anim {
            transition-delay: 1.5s;
            transform: translateX(0) rotate(0);
        }
        .b-intro_anim {
            transform: translateX(0);
        }
        .-delay1 {
            transition-delay: 1.52s;
        }
        .-delay2 {
            transition-delay: 1.54s;
        }
        .c-breadcrumb, .b-intro_share {
            transition-delay: 1.5s;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.is-loaded.is-ready.is-animated.is-transitioned {
    .b-intro {
        &::after {
            transition-delay: 0.6s;
        }
        .b-intro_image_anim {
            transition-delay: 0.6s;
        }
        .-delay1 {
            transition-delay: 0.7s;
        }
        .-delay2 {
            transition-delay: 0.75s;
        }
        .c-breadcrumb, .b-intro_share {
            transition-delay: 0.6s;
        }
    }
}