.b-organigramme{
    .b-organigramme_niveau{
        display: grid;
        grid-template-columns: 3rem 1fr 3rem;
        align-items: center;
        position: relative;
        grid-gap: rem($unit-tiny);
    }

    .b-organigramme_niveau_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 2px solid #F0F0F0;
    }

    // .b-cards_slider_left{
    //     padding: 0 1rem 0 0;
    // }

    // .b-cards_slider_right{
    //     position: absolute;
    //     right: -7.5rem;
    //     bottom: 50%;
    //     top: 50%;
    // }

    .b-organigramme_slider {
        min-width: 100%;
    }
}
