.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;

  background-color: $white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba($primary-light, 0.2);

  .c-header_wrap {
    display: flex;
    align-items: center;
    grid-gap: rem($unit-small);
  }

  .c-header_nav_burger {
    display: flex;
    align-items: center;
    grid-gap: rem($unit-tiny);
    .c-header_nav-icon {
      position: relative;
      cursor: pointer;
      width: rem(35px);
      height: rem(23px);
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 2px;
        width: rem(35px);
        background: $primary;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: $speed $easing;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 10px;
        }
        &:nth-child(3) {
          top: 20px;
        }
      }
    }
    &.is-active {
      .c-header_nav-icon {
        span {
          &:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            left: -60px;
          }
          &:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .c-header_nav_burger_label {
    transition: $speed $easing;
  }

  @media (min-width: $from-small) {
    padding: 0 rem(40px);
  }
  @media (max-width: $to-small) {
    padding: 0 rem(20px);
  }
}

.c-header_accessibility {
  position: fixed;
  top: calc(#{rem($header-height)});
  left: 0;
  width: rem(50px);
  height: rem(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $second;
  color: $white;
  z-index: 10;
  font-weight: $bold;
  transition: left 750ms cubic-bezier(.23, 1, .32, 1);
  &.is-active {
    left: 180px;
  }
}
.pojo-a11y-toolbar-toggle-link {
  display: none!important;
}