.c-heading {

    &.-h1 {
        font-size: rem($font-size-h1);
        line-height: rem($font-size-h1 + 2px);
        font-weight: $medium;
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 10px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        line-height: rem($font-size-h2 + 2px);
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 5px);
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        line-height: rem($font-size-h3 + 2px);
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 5px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        line-height: rem($font-size-h4 + 2px);
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h4)} - 4px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        line-height: rem($font-size-h5 + 2px);
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        line-height: rem($font-size-h6 + 2px);
    }

    &.-outline {
      border: 3px solid $primary-light;
      padding: rem($unit-tiny);
      display: inline-block;
      color: $primary-light;
      &.u-c-white {
        border-color: $white;
        color: $white;
      }
    }

    &.-bg {
      padding: rem($unit-tiny);
      background-color: $second;
      color: $white;
      display: inline-block;
      &.-white {
        background-color: $white;
        color: $second;
      }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
