.c-nav {
  position: fixed;
  top: #{rem($header_height)};
  left: 0;
  right: 0;
  z-index: 9;
  //visibility: hidden;
  transform: translateY(calc(-100% - #{rem($header_height)}));
  transition: transform 1s $easing;
  height: 100vh;
  overflow: hidden;

  .c-nav_wrap {
    background-color: $primary;
    overflow-x: auto;
    height: 100vh;
    position: relative;

    .o-container{
      position: relative;
      z-index: 2;
    }
    
    &::after{
      content: '';
      height: 35%;
      width: 100%;
      background-color: $second;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      transform-origin: top;
      transition: 0.8s $easing 0.2s;
    }
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-nav_primary {
    display: grid;
    gap: rem($unit-tiny);
    color: $white;
  }

}

.has-navOpen {
  .c-nav {
    visibility: visible;
    transform: translateY(0);

    .c-nav_wrap{
      &::after{
        height: 0;
      }
    }
  }
}
